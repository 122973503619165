<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12">
                <sale-invoice :saleId="this.$route.params.id"></sale-invoice>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import saleInvoice from "../../components/sale-invoice.vue";

export default {
    name: 'SaleInvoices',

    components: {
		"sale-invoice": saleInvoice,
    },
}
</script>
